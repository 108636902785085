import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';

import BadgeStatus from 'ui-kit/bagde/badge-status';

// Componets
import AutoRefillBody from 'components/auto-refill-body/auto-refill-body';
import ProfileLayout from 'components/layouts/profile/profile.layout';

// State
import { accountProfileSelector } from 'state/account/account.selectors';

// Styles
import './auto-refill.style.scss';

const AutoRefill = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);

    profileObject?.addresses.map((address) => {
        if (address.state === 'CA' && address.defaultShip) {
            navigate('/secure/medicine-cabinet');
            return null;
        }
        return null;
    });

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.autoRefill.title`)}
            heading={t(`pages.profile.autoRefill.heading`)}
        >
            <div className="auto-refill-status">
                <BadgeStatus
                    label={`${
                        profileObject?.autoRefill
                            ? t('pages.profile.autoRefill.status.on')
                            : t('pages.profile.autoRefill.status.off')
                    }`}
                    variant={`${profileObject?.autoRefill ? 'success' : 'smoke'}`}
                />
            </div>
            <AutoRefillBody />
        </ProfileLayout>
    );
};

export default AutoRefill;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
